.image-wrapper {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.image {
    width: 250px;
    height: 350px;
    background-image: url("../../assets/img/header-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}

.title-wrapper {
    position: relative;
    height: 130px;
    margin-bottom: 2rem;
}

.title-content {
    position: absolute;
    bottom: 20px;
    left: 15px;
    width: 100%;
    height: 100%;
    max-width: 345px;
    box-sizing: border-box;
    background: #063970;
    padding: 15px 20px;
}

h3, h5 {
    color: #fcf4e6;
    max-width: 300px;
}

.title {
    font-size: 32px;
    line-height: 36px;
}

.sub-title {
    opacity: 80%;
    font-size: 18px;
    line-height: 22px;
    margin-top: 5px;
}

@media (min-width: 576px) {
    .image {
        width: 400px;
        height: 540px;
    }

    .title-content {
        max-width: 550px;
    }
}
@media (min-width: 768px) {
    .image {
        width: 450px;
        height: 630px;
    }

    .title-content {
        max-width: 700px;
    }
}
@media (min-width: 950px) {
    .image-wrapper {
        padding-left: 20%;
    }
}


