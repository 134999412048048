* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.App {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
