.instagram-icon {
    background-image: url("../../assets/icons/blue-instagram.svg");
}

.telegram-icon {
    background-image: url("../../assets/icons/blue-telegram.svg");
}

.tiktok-icon {
    background-image: url("../../assets/icons/blue-tiktok.svg");
}
